import React from "react";
import { Link } from "react-router-dom";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import "./surahItem.scss";

const SurahItem = ({ surah }) => {
  return (
    <Link key={surah.name} to={`/quran/${surah.number}`} className="item">
      <div className="item--number">
        <span>{toFarsiNumber(surah.number)}</span>
      </div>
      <div className="item__arabicName">
        <h3 className="item--name">{surah.name}</h3>
        <p className="item--ayeh">{toFarsiNumber(surah.numberOfAyahs)} آیه</p>
      </div>
      <div className="item__englishNames">
        <p className="item--englishName">{surah.englishName}</p>
        <p className="item--englishTranslate">{surah.englishNameTranslation}</p>
      </div>
    </Link>
  );
};

export default SurahItem;
