import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { channelCategories } from "./channelsData";

import "./channelSlider.scss";

const ChannelSlider = ({ imagesObject, category }) => {
  const [images, setImages] = useState([]);
  const [channelsName, setChannelsName] = useState([]);

  useEffect(() => {
    setImages(Object.keys(imagesObject));
    setChannelsName(channelCategories[category]);
  }, [category]);

  return (
    <div className="channelsContainer">
      <Splide
        options={{
          perPage: 11,
          breakpoints: {
            1200: {
              perPage: 10
            },
            1100: {
              perPage: 9
            },
            1000: {
              perPage: 8
            },
            880: {
              perPage: 7
            },
            768: {
              perPage: 6
            },
            670: {
              perPage: 5
            },
            515: {
              perPage: 4
            },
            430: {
              perPage: 3
            },
            330: {
              perPage: 2
            },
          },
          perMove: 1,
          width: "100%",
          gap: "1rem",
          pagination: false,
          direction: "rtl",
        }}
        aria-label="My Favorite Images"
      >
        {images.map((image, i) => {
          return (
            <SplideSlide key={image}>
              <div className="channels">
                <img
                  className="channels--logo"
                  src={imagesObject[image]}
                  alt={image}
                />
                <p className="channels--name">{channelsName[i]}</p>
              </div>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
};

export default ChannelSlider;
