import React, { useEffect, useState } from "react";
import axios from "axios";
import "./quranPlayer.scss";
import { Link } from "react-router-dom";
import {
  IoPlayOutline,
  IoPlaySkipForwardOutline,
  IoPlaySkipBackOutline,
  IoVolumeHighOutline,
  IoPauseOutline,
  IoVolumeLowOutline,
  IoVolumeMuteOutline,
  IoVolumeMediumOutline,
} from "react-icons/io5";
import Slider from "@mui/material/Slider";
import { useSelector } from "react-redux";

const QuranPlayer = () => {
  const [surahs, setSurahs] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ number: 1, index: 0 });
  const [selectedSurah, setSelectedSurah] = useState([]);
  const [tarjomeh, setTarjomeh] = useState([]);
  const [audio, setAudio] = useState(1);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const { quran } = useSelector((state) => state);

  const getSurah = async (number) => {
    const surah = await axios.get(`/api/surahs/${number}`);
    setSelectedSurah(surah.data);

    const tarjomeh = await axios.get(`/api/translations/${number}`);

    // const tarjomeh = await axios.get(
    //   `http://api.alquran.cloud/v0/surah/${number}/editions/en.asad`
    // );
    setTarjomeh(tarjomeh.data.ayahs);

    setAudio(number);
  };

  useEffect(() => {
    setSurahs(
      quran?.surahs?.data?.filter(
        (surah) =>
          surah.number === 1 ||
          surah.number === 36 ||
          surah.number === 67 ||
          surah.number === 112 ||
          surah.number === 113 ||
          surah.number === 114
      )
    );
    // }
    getSurah(36);
  }, []);

  useEffect(() => {
    const getdata = async () => {
      try {
        const res = await axios.get("/api/surahs");
        setSurahs(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getdata();
  }, []);

  const playingToggle = (playState) => {
    if (playState === "play") {
      document.getElementById("player").play();
      setPlaying(true);
    } else {
      document.getElementById("player").pause();
      setPlaying(false);
    }
  };

  const handlePervious = () => {
    if (selectedItem - 1 < 0) {
      return;
    } else {
      playingToggle("pause");
      const surah = surahs[selectedItem.index - 1];
      setSelectedItem({ number: surah.number, index: selectedItem.index - 1 });
      getSurah(surah.number);
    }
  };

  const handleNext = () => {
    if (selectedItem + 1 > surahs.length - 1) {
      return;
    } else {
      playingToggle("pause");
      const surah = surahs[selectedItem.index + 1];
      setSelectedItem({ number: surah.number, index: selectedItem.index + 1 });
      getSurah(surah.number);
    }
  };

  // setAudio(
  //   new Audio(
  //     `https://cdn.islamic.network/quran/audio-surah/128/ar.alafasy/${number}.mp3`
  //   )
  // );

  const handleVolume = (e) => {
    document.getElementById("player").volume = e.target.value;
    setVolume(e.target.value);
  };

  return (
    <div className="quran__container   w-full h-screen py-12 px-0 flex justify-between flex-row-reverse">
      <div className="quran__container__playList   h-full p-4 basis-[30%]  bg-player-bg-dark flex justify-between flex-col overflow-y-scroll rounded-lg">
        {surahs?.map((surah, index) => {
          return (
            <Link
              key={surah.name}
              to="#"
              className={
                surah.number === selectedItem.number
                  ? "quran__container__playList__item selected   w-full mt-0 mb-2 mx-0 py-6 px-4 flex items-center rounded-lg no-underline"
                  : "quran__container__playList__item   w-full bg-player-surah-bg-light mt-0 mb-2 mx-0 py-6 px-4 flex items-center rounded-lg no-underline"
              }
              onClick={() => {
                playingToggle("pause");
                setSelectedItem({ number: surah.number, index });
                getSurah(surah.number);
              }}
            >
              <p
                className={
                  surah.number === selectedItem.number
                    ? "quran__container__playList__item--number selectedNumber w-10 h-10 ml-4 rounded-lg flex justify-center items-center font-medium font-['Ubuntu',sans-serif]"
                    : "quran__container__playList__item--number   w-10 h-10 ml-4 text-color-light bg-player-num-bg-grey rounded-lg flex justify-center items-center font-medium font-['Ubuntu',sans-serif]"
                }
              >
                {surah.number}
              </p>
              <div className="quran__container__playList__item__details   flex justify-between items-center flex-[1]">
                <h3 className="quran__container__playList__item__details--name   pl-[.3rem] text-color-light font-normal font-['Noto_Naskh_Arabic',serif]">
                  {surah.name}
                </h3>
                <p className="quran__container__playList__item__details--englishName   text-color-grey font-['Ubuntu',sans-serif] font-normal">
                  {surah.englishName}
                </p>
                <p className="quran__container__playList__item__details--englishName   text-color-grey font-['Ubuntu',sans-serif] font-normal">
                  {surah.englishNameTranslation}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="quran__container__playerContainer   h-full bg-player-bg-dark basis-[65%] rounded-lg">
        <div className="quran__container__playerContainer__quran   h-[85%] flex flex-col justify-between overflow-y-scroll items-center">
          {selectedSurah?.ayahs?.map((ayah, index) => (
            <div
              key={ayah.number}
              className="quran__container__playerContainer__quran__text   w-[90%] py-4 px-0 flex-[1]"
            >
              <p className="quran__container__playerContainer__quran__text--ayah   text-color-light font-['Noto_Naskh_Arabic',serif] text-right text-[1.3em]">
                {ayah.text}
              </p>
              <p className="quran__container__playerContainer__quran__text--tarjomeh   text-color-grey text-left">
                {tarjomeh[index]?.text}
              </p>
            </div>
          ))}
        </div>
        <div className="quran__container__playerContainer__player   h-[15%] p-10 flex justify-between items-center">
          <div className="quran__container__playerContainer__player__controls   w-full m-0 py-[.8rem] px-6 bg-color-darkest rounded-lg flex justify-between items-center gap-4">
            <div className="quran__container__playerContainer__player__controls__buttons   py-[.3rem] px-[.3rem] basis-[18%] text-color-light flex justify-around items-center cursor-pointer">
              <audio
                id="player"
                src={`/api/talavats/${audio}`}
                onEnded={() => setPlaying(false)}
              ></audio>
              <IoPlaySkipBackOutline
                onClick={handlePervious}
                className="text-[1.5em]"
              />
              {playing ? (
                <IoPauseOutline
                  className="text-[1.5em]"
                  onClick={() => playingToggle("pause")}
                />
              ) : (
                <IoPlayOutline
                  className="text-[1.5em]"
                  onClick={() => playingToggle("play")}
                />
              )}
              <IoPlaySkipForwardOutline
                onClick={handleNext}
                className="text-[1.5em]"
              />
            </div>
            <p className="quran__container__playerContainer__player__controls__title text-color-light  py-[.3rem]  px-[.8rem] bg-player-bg-dark flex justify-between items-center gap-2">
              <span className="quran__container__playerContainer__player__controls__title--english   text-color-light font-['Ubuntu',sans-serif]">
                {selectedSurah?.englishName}
              </span>
              -
              <span className="quran__container__playerContainer__player__controls__title--arabic   text-color-light font-['Noto_Naskh_Arabic',serif]">
                {selectedSurah?.name}
              </span>
            </p>
            <div className="quran__container__playerContainer__player__controls__volume  py-[.1rem] px-2 text-color-light flex justify-between items-center gap-4 basis-[18%]">
              {(volume === 0 && (
                <IoVolumeMuteOutline className="text-[2em]" />
              )) ||
                (volume > 0 && volume < 0.3 && (
                  <IoVolumeLowOutline className="text-[2em]" />
                )) ||
                (volume >= 0.3 && volume < 0.7 && (
                  <IoVolumeMediumOutline className="text-[2em]" />
                )) ||
                (volume >= 0.6 && (
                  <IoVolumeHighOutline className="text-[2em]" />
                ))}
              <Slider
                sx={{
                  color: "white",
                  "& .MuiSlider-thumb": {
                    display: "none",
                  },
                }}
                value={volume}
                onChange={handleVolume}
                step={0.1}
                min={0}
                max={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuranPlayer;
