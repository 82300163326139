import React, { useEffect, useRef } from "react";

export const VideoJS = (props) => {
  const { options } = props;

  // useEffect(() => {
  //   window.jwplayer("video-player").setup({
  //     // file: "https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8",
  //     file: options.sources[0].src,
  //     // file: "http://localhost:7700/api/stream/",
  // width: "100%",
  // aspectratio: "16:9",
  //     advertising: {
  //       client: "vast",
  //       tag: require("./preroll.xml"),
  //       rules: {
  //         startOn: 0,
  //         frequency: 1,
  //       },
  //     },
  //   });
  // }, []);
  // return (
  //   <div className="App">
  //     <div id="video-player" className="embed-responsive-item"></div>
  //   </div>
  // );
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current && typeof window !== "undefined") {
      window.jwplayer(playerRef.current).setup({
        file: options.sources[0].src,
        width: "100%",
        aspectratio: "16:9",
      });
    }
  }, []);

  return <div ref={playerRef}></div>;
};
export default VideoJS;
