import React, { useEffect, useState } from "react";
import "./courses.scss";
import axios from "axios";

import CourseCard from "../../Components/courseComponents/courseCard/CourseCard";
import { useLocation } from "react-router-dom";
import { BsSortDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../reducers/course/courseSlice";

const Courses = () => {
  const [filteredCourses, setFilteredCourses] = useState();
  const [courseData, setCourseData] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");
  const [activeTab, setActiveTab] = useState("all");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  const {
    courses: { courses },
  } = useSelector((state) => state);

  const location = useLocation();
  const courseName = location.state?.courseName;

  useEffect(() => {
    axios.get("/api/course").then((response) => {
      setCourseData(response.data);
      // console.log("API Response:", response.data);
    });
  }, []);

  useEffect(() => {
    if (courseName === "namaz") {
      setCourseTitle("نماز");
    } else if (courseName === "qoran") {
      setCourseTitle("قرآن");
    }
  }, []);

  useEffect(() => {
    let filtered = courses?.filter((course) => {
      return course.publishStatus === "published";
    });
    if (activeTab === "popular") {
      filtered = filtered.sort((a, b) => a.ratingAvg - b.ratingAvg);
    }
    setFilteredCourses(filtered);
  }, [activeTab]);

  return (
    <div className="courses">
      <div className="courses__header">
        <h1>همه دوره ها</h1>
        <span className="courses__header--icon"></span>
      </div>
      <div className="courses__filter">
        <div className="courses__filter__title">
          <span>: مرتب سازی</span>
          <BsSortDown
            fontSize="large"
            className="courses__filter__title--icon"
          />
        </div>
        <div className="courses__filter__items">
          <span
            onClick={() => {
              setActiveTab("all");
            }}
            className={
              activeTab === "all"
                ? `courses__filter__items__btn filterActiveTab`
                : `courses__filter__items__btn`
            }
          >
            همه دوره ها
          </span>
          <span
            onClick={() => {
              setActiveTab("cheap");
            }}
            className={
              activeTab === "cheap"
                ? `courses__filter__items__btn filterActiveTab`
                : `courses__filter__items__btn`
            }
          >
            ارزان ترین
          </span>
          <span
            onClick={() => {
              setActiveTab("expensive");
            }}
            className={
              activeTab === "expensive"
                ? `courses__filter__items__btn filterActiveTab`
                : `courses__filter__items__btn`
            }
          >
            گران ترین
          </span>
          <span
            onClick={() => {
              setActiveTab("popular");
            }}
            className={
              activeTab === "popular"
                ? `courses__filter__items__btn filterActiveTab`
                : `courses__filter__items__btn`
            }
          >
            پرمخاطب ها
          </span>
        </div>
      </div>
      <div className="courses__container">
        {filteredCourses?.map((course) => (
          <CourseCard key={course._id} {...course} isSlider={true} />
        ))}
      </div>
    </div>
  );
};

export default Courses;
