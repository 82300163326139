import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAuthBox: false,
};

export const authSlice = createSlice({
  name: "showAuthBox",
  initialState,
  reducers: {
    showAuthBox: (state, action) => {
      state.showAuthBox = !state.showAuthBox;
    },
  },
});

export const { showAuthBox } = authSlice.actions;
export default authSlice.reducer;
