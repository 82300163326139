import React from "react";
import { MdOutlineEdit } from "react-icons/md";
import { RiBookmarkLine } from "react-icons/ri";
import "./favorite.scss";

const Favorite = () => {
  return (
    <div className="profile__main__leftContainer__left">
      <div className="profile__main__leftContainer__left__header">
        <h3 className="profile__main__leftContainer__left__header--title">
          ورزش های مورد علاقه
        </h3>
        <button className="profile__main__leftContainer__left__header--btn">
          <MdOutlineEdit className="profile__main__leftContainer__left__header--btn--svg" />
          ویرایش
        </button>
      </div>
      <div className="profile__main__leftContainer__left__choice">
        <div className="profile__main__leftContainer__left__choice__sectionmark">
          <div className="profile__main__leftContainer__left__choice__sectionmark__logo">
            <RiBookmarkLine />
          </div>
        </div>
        <h3 className="profile__main__leftContainer__left__choice--text">
          شما هنوز ورزشی را انتخاب نکرده اید. سه ورزش مورد علاقه خود را وارد
          کنید.
        </h3>
      </div>

      <div className="profile__main__leftContainer__left__header">
        <h3 className="profile__main__leftContainer__left__header--title">
          ورزش های مورد علاقه
        </h3>
        <button className="profile__main__leftContainer__left__header--btn">
          <MdOutlineEdit className="profile__main__leftContainer__left__header--btn--svg" />
          ویرایش
        </button>
      </div>
      <div className="profile__main__leftContainer__left__choice">
        <div className="profile__main__leftContainer__left__choice__sectionmark">
          <div className="profile__main__leftContainer__left__choice__sectionmark__logo">
            <RiBookmarkLine />
          </div>
        </div>
        <h3 className="profile__main__leftContainer__left__choice--text">
          شما هنوز ورزشی را انتخاب نکرده اید. سه ورزش مورد علاقه خود را وارد
          کنید.
        </h3>
      </div>

      <div className="profile__main__leftContainer__left__header">
        <h3 className="profile__main__leftContainer__left__header--title">
          ورزش های مورد علاقه
        </h3>
        <button className="profile__main__leftContainer__left__header--btn">
          <MdOutlineEdit className="profile__main__leftContainer__left__header--btn--svg" />
          ویرایش
        </button>
      </div>
      <div className="profile__main__leftContainer__left__choice">
        <div className="profile__main__leftContainer__left__choice__sectionmark">
          <div className="profile__main__leftContainer__left__choice__sectionmark__logo">
            <RiBookmarkLine />
          </div>
        </div>
        <h3 className="profile__main__leftContainer__left__choice--text">
          شما هنوز ورزشی را انتخاب نکرده اید. سه ورزش مورد علاقه خود را وارد
          کنید.
        </h3>
      </div>
    </div>
  );
};

export default Favorite;
