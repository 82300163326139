import React, { useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { AuthContext } from "../../authContext/AuthContext";
import { showLoginBox } from "../../reducers/auth/loginSlice";
import { login } from "../../authContext/apiCalls";

import "./mobileAuth.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import PasswordReset from "./PasswordReset";

const SignupSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("شماره همراه خود را وارد نمایید")
    .min(11, "شماره وارد شده صحیح نمیباشد")
    .max(11, "شماره وارد شده صحیح نمیباشد")
    .matches(
      /(0|\+98)?([ ]|-|[()]){0,2}9[0|1|2|3|4|9]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}/gi,
      "شماره وارد شده صحیح میباشد"
    ),
  password: Yup.string()
    .required("رمز عبور مورد نیاز است")
    .min(4, "رمز باید بین ۴ تا ۳۲ کاراکتر باشد")
    .max(32, "رمز باید بین ۴ تا ۳۲ کاراکتر باشد"),
});

const MobileLogin = () => {
  const { dispatch } = useContext(AuthContext);
  const [forgotPassword, setForgotPassword] = useState(false);

  const dispatchState = useDispatch();

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      password: "",
    },

    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const res = await login(
        { phoneNumber: values.phoneNumber, password: values.password },
        dispatch,
        dispatchState,
        true
      );

      if (res === "success") {
        toast.success("!به قرآن من خوش آمدید", {
          bodyStyle: {
            textAlign: "right",
          },
        });
      } else {
        toast.error("!اطلاعات وارد شده درست نمی‌باشند", {
          bodyStyle: {
            textAlign: "right",
          },
        });
      }
    },
  });

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  return (
    <div
      className="auth__container"
      onClick={() => dispatchState(showLoginBox())}
    >
      <ToastContainer />
      <div
        className="auth__container__form"
        onClick={(e) => e.stopPropagation()}
      >
        {!forgotPassword ? (
          <>
            <div>
              <h1 className="auth__container__form--title">
                ورود به <span>قرآن من</span>
              </h1>

              <p className="auth__container__form--text">
                جهت بازدید از برنامه ها ابتدا وارد حساب کاربری خود شوید
              </p>
            </div>
            <div className="auth__container__form__inputContainer">
              <input
                className="auth__container__form__inputContainer--input"
                placeholder="۰۹۱۲  ۳۴۵  ۶۷۸۹"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="auth__container__form__inputContainer--errorText">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
              <input
                className="auth__container__form__inputContainer--input auth__container__form__inputContainer--loginInput"
                placeholder="گذرواژه"
                type="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="auth__container__form__inputContainer--errorText">
                  {formik.errors.password}
                </div>
              ) : null}
            </div>
            <p
              className="auth__container__form__inputContainer--forgotText"
              onClick={handleForgotPassword}
            >
              رمز عبور خود را فراموش کرده اید؟
            </p>
            <button
              className="auth__container__form--button"
              type="submit"
              onClick={formik.handleSubmit}
            >
              ورود
            </button>
          </>
        ) : (
          <PasswordReset setForgotPassword={setForgotPassword} />
        )}
      </div>
    </div>
  );
};

export default MobileLogin;
