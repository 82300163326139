import React from "react";
import { MdOutlineSubscriptions } from "react-icons/md";
import {
  RiBookmarkLine,
  RiUserLine,
  RiLogoutBoxRLine,
  RiHistoryLine,
} from "react-icons/ri";
import "./menu.scss";
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div className="profile__main__right">
      <div className="profile__main__right__container">
        <div className="profile__main__right__container__userinfo">
          <div className="profile__main__right__container__userinfo--pic">
            <RiUserLine size={54} />
          </div>
          <h5 className="profile__main__right__container__userinfo--text">
            ۰۹۱۲۳۴۵۶۷۸۹
          </h5>
        </div>
        <hr />
        <ul className="profile__main__right__container__menu">
          <li className="profile__main__right__container__menu__item">
            <Link
              to="/profile/account"
              className="profile__main__right__container__menu__item__link"
            >
              <RiUserLine className="profile__main__right__container__menu__item__link--logo" />
              <span className="profile__main__right__container__menu__item__link--text">
                حساب کاربری
              </span>
            </Link>
          </li>
          <li className="profile__main__right__container__menu__item">
            <Link
              to="/profile/favorites"
              className="profile__main__right__container__menu__item__link"
            >
              <RiBookmarkLine className="profile__main__right__container__menu__item__link--logo" />
              <span className="profile__main__right__container__menu__item__link--text">
                علاقه مندی ها
              </span>
            </Link>
          </li>
          <li className="profile__main__right__container__menu__item">
            <Link
              to="/profile/subscriptions"
              className="profile__main__right__container__menu__item__link"
            >
              <MdOutlineSubscriptions className="profile__main__right__container__menu__item__link--logo" />
              <span className="profile__main__right__container__menu__item__link--text">
                اشتراک
              </span>
            </Link>
          </li>
          <li className="profile__main__right__container__menu__item">
            <Link
              to="/profile/payments"
              className="profile__main__right__container__menu__item__link"
            >
              <RiHistoryLine className="profile__main__right__container__menu__item__exit--logo" />
              <span className="profile__main__right__container__menu__item__link--text">
                سوابق پرداخت
              </span>
            </Link>
          </li>
          <li className="profile__main__right__container__menu__item">
            <Link
              to="#"
              className="profile__main__right__container__menu__item__exit"
            >
              <RiLogoutBoxRLine className="profile__main__right__container__menu__item__exit--logo" />
              <span className="profile__main__right__container__menu__item__exit--text">
                خروج
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
