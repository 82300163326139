import React from "react";
import "./paymentRecords.scss";
import { RiHistoryLine } from "react-icons/ri";

const PaymentRecords = () => {
  return (
    <div className="profile__main__leftContainer__left">
      <div className="profile__main__leftContainer__left__sectionmark">
        <div className="profile__main__leftContainer__left__sectionmark__logo">
          <RiHistoryLine size={54} />
        </div>
      </div>
      <div className="profile__main__leftContainer__left__create">
        <p className="profile__main__leftContainer__left__create--text">
          شما تا کنون پرداختی نداشته‌اید
        </p>
        <div className="profile__main__leftContainer__left__create__submit">
          <button className="profile__main__leftContainer__left__create__submit--button">
            بروز رسانی
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentRecords;
