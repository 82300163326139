import React, { useState } from "react";
import Navbar from "../../Components/navbar/Navbar";
import Favorite from "../../Components/profile/Favorite";
import Menu from "../../Components/profile/Menu";
import PaymentRecords from "../../Components/profile/PaymentRecords";
import Subscription from "../../Components/profile/Subscription";
import UserInfo from "../../Components/profile/UserInfo";
import "./profile.scss";
import { Route, Routes, useParams } from "react-router-dom";

const Profile = () => {
  const selectedItem = useParams();

  return (
    <>
      <div className="profile">
        <div className="profile__main">
          <Menu />
          <div className="profile__main__leftContainer">
            {selectedItem.placeholder === "account" && <UserInfo />}
            {selectedItem.placeholder === "favorites" && <Favorite />}
            {selectedItem.placeholder === "subscriptions" && <Subscription />}
            {selectedItem.placeholder === "payments" && <PaymentRecords />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
