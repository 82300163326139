import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { passwordStrength } from "check-password-strength";

import Countdown, { zeroPad } from "react-countdown";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import "./mobileAuth.scss";

const PasswordReset = ({ setForgotPassword }) => {
  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(4, "رمز باید بین ۴ تا ۳۲ کاراکتر باشد")
      .max(32, "رمز باید بین ۴ تا ۳۲ کاراکتر باشد")
      .required("رمز عبور مورد نیاز است"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "رمزهای عبور باید مطابقت داشته باشند")
      .required("لطفا رمز عبور خود را تایید کنید"),
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [phoneIncorrect, setPhoneIncorrect] = useState(false);
  const [verifyingNumber, setVerifyingNumber] = useState(false);
  const [isWrongCode, setIsWrongCode] = useState(false);
  const [sentCode, setSentCode] = useState("");
  const [userExist, setUserExist] = useState(true);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [resendButton, setResendButton] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [resendCounter, setResendCounter] = useState(0);
  const [userPasswordStrength, setUserPasswordStrength] = useState({});
  const [user, setUser] = useState({});
  const [totalTime, setTotalTime] = useState(Date.now() + 120000);

  const strength = {
    "Too weak": "بسیار ضعیف",
    Weak: "ضعیف",
    Medium: "متوسط",
    Strong: "عالی",
  };

  function generateRandomNumber() {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
  }

  const handleIsPhoneCorrect = () => {
    const mobileReg =
      /(0|\+98)?([ ]|-|[()]){0,2}9[0|1|2|3|4|9]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}/gi;

    if (phoneNumber.match(mobileReg) && phoneNumber.length === 11) {
      checkNumberExistance();
    } else {
      setPhoneIncorrect(true);
    }
  };

  const handleVerification = async () => {
    setPhoneNumber(phoneNumber);
    const result = sendVerificationCode(
      generateRandomNumber,
      setSentCode,
      phoneNumber
    );
    if (result) {
      setVerifyingNumber(true);
    }
  };

  const checkNumberExistance = async () => {
    try {
      const res = await axios.post(
        "/api/check-existance",
        { phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.length === 0) {
        setUserExist(false);
      } else {
        handleVerification();
        setUser(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function sendVerificationCode(
    generateRandomNumber,
    setSentCode,
    phoneNumber
  ) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let generatedCode = generateRandomNumber();
    setSentCode(generatedCode);
    const body = {
      ParameterArray: [
        {
          Parameter: "verificationCode",
          ParameterValue: generatedCode,
        },
      ],
      Mobile: phoneNumber,
      TemplateId: "71650",
      UserApiKey: process.env.REACT_APP_SMS_API_KEY,
      SecretKey: process.env.REACT_APP_SMS_API_SECRET,
    };

    try {
      const res = await axios.post(
        "https://RestfulSms.com/api/UltraFastSend/direct",
        body,
        config
      );
      return res.data.IsSuccessful;
    } catch (err) {
      console.log(err);
    }
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      try {
        await axios
          .put(`/api/users/reset-password/${user[0]._id}`, {
            password: values.password,
          })
          .then(() =>
            toast.success("!بروزرسانی گذرواژه با موفقیت انجام شد", {
              theme: "dark",
              bodyStyle: {
                textAlign: "right",
              },
            })
          );

        setForgotPassword(false);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePhoneInputChange = (e) => {
    setUserExist(true);
    setPhoneIncorrect(false);
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };

  const handleVerify = () => {
    if (sentCode === Number(verificationCode)) {
      setVerifyingNumber(false);
      setPhoneVerified(true);
    } else {
      setIsWrongCode(true);
    }
  };

  function toFarsiNumber(n) {
    const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

    return n.toString().replace(/\d/g, (x) => farsiDigits[x]);
  }

  useEffect(() => {
    setUserPasswordStrength(passwordStrength(formik.values.password));
  }, [formik.values.password]);

  const handleResendCode = () => {
    setResendButton(false);
    setResetTimer(true);
    sendVerificationCode(generateRandomNumber, setSentCode, phoneNumber);
  };

  return (
    <>
      {!phoneVerified ? (
        !verifyingNumber ? (
          <>
            <div>
              <h1 className="auth__container__form--title">بازیابی رمز عبور</h1>

              <p className="auth__container__form--text">
                جهت بازیابی رمز عبور حساب خود لطفا شماره همراه خود را وارد
                نمایید
              </p>
            </div>
            <div className="auth__container__form__inputContainer">
              <input
                className="auth__container__form__inputContainer--input"
                placeholder="۰۹۱۲  ۳۴۵  ۶۷۸۹"
                onChange={(e) => handlePhoneInputChange(e)}
                value={phoneNumber}
              />
              <div className="auth__container__form__inputContainer__errorContainer--errorText">
                {phoneIncorrect && (
                  <p className="auth__container__form__inputContainer--errorText">
                    شماره تلفن اشتباه است
                  </p>
                )}
                {!userExist && (
                  <p className="auth__container__form__inputContainer--errorText">
                    حساب کاربری با شماره همراه وارد شده وجود ندارد
                  </p>
                )}
              </div>
            </div>
            {!disableButton ? (
              <button
                className="auth__container__form--button"
                onClick={() => {
                  setDisableButton(true);
                  handleIsPhoneCorrect();
                  setDisableButton(false);
                }}
              >
                دریافت کد تایید
              </button>
            ) : (
              <div className="auth__container__form--progressIcon">
                <CircularProgress size={22} />
              </div>
            )}
          </>
        ) : (
          <>
            <div>
              <h1 className="auth__container__form--codeTitle">
                کد ۶ رقمی به شماره <span>{toFarsiNumber(phoneNumber)}</span>{" "}
                ارسال شد
              </h1>
              <p className="auth__container__form--text">
                کد دریافتی را وارد نمایید
              </p>
            </div>
            <div className="auth__container__form__inputContainer">
              <input
                className="auth__container__form__inputContainer--input"
                placeholder="۱۲۳۴۵۶"
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                }}
                value={verificationCode}
              />
              {isWrongCode && (
                <p className="auth__container__form__inputContainer--errorText">
                  کد وارد شده اشتباه است
                </p>
              )}
            </div>
            {resendCounter <= 2 && (
              <div className="auth__container__form__resendCode">
                <Countdown
                  key={resetTimer}
                  date={totalTime}
                  intervalDelay={1}
                  precision={3}
                  renderer={({ minutes, seconds }) => (
                    <span>
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                  )}
                  onComplete={() => {
                    setResendButton(true);
                    setResetTimer(false);
                    setResendCounter(resendCounter + 1);
                  }}
                  onStart={() => {
                    setResendButton(false);
                    setResetTimer(false);
                  }}
                />
                <button
                  disabled={!resendButton}
                  className="auth__container__form__resendCode--button"
                  onClick={handleResendCode}
                >
                  ارسال مجدد کد
                </button>
              </div>
            )}
            {!disableButton ? (
              <button
                className="auth__container__form--button"
                onClick={() => {
                  setDisableButton(true);
                  handleVerify();
                  setDisableButton(false);
                }}
              >
                ورود
              </button>
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}
          </>
        )
      ) : (
        <>
          <div>
            <h1 className="auth__container__form--title">ایجاد گذرواژه جدید</h1>

            <p className="auth__container__form--text">
              گذرواژه مورد نظر خود را وارد و آن را تایید نماید
            </p>
          </div>
          <div className="auth__container__form__inputContainer">
            <input
              className="auth__container__form__inputContainer--input"
              placeholder="گذرواژه (بین ۴ تا ۳۲ کاراکتر) "
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <div className="auth__container__form__inputContainer__errorContainer">
              {formik.values.password !== "" && userPasswordStrength !== {} && (
                <p className="auth__container__form__inputContainer__errorContainer--errorText">
                  <span>کیفیت گذرواژه: </span>
                  {strength[userPasswordStrength.value]}
                </p>
              )}
              {formik.touched.password && formik.errors.password ? (
                <div className="auth__container__form__inputContainer__errorContainer--errorText">
                  {formik.errors.password}
                </div>
              ) : null}
            </div>

            <input
              className="auth__container__form__inputContainer--input"
              placeholder="تایید رمز عبور"
              name="confirmPassword"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="auth__container__form__inputContainer__errorContainer--errorText">
                {formik.errors.confirmPassword}
              </p>
            ) : null}
          </div>
          {!disableButton ? (
            <button
              type="submit"
              className="auth__container__form--button"
              onClick={() => {
                setDisableButton(true);
                formik.handleSubmit();
                setDisableButton(false);
              }}
            >
              ثبت نام
            </button>
          ) : (
            <div className="auth__container__form--progressIcon">
              <CircularProgress size={22} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PasswordReset;
