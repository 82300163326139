import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container py-24 px-12 bg-[#172542] flex items-center flex-row">
        <div className="footer__container__copyrights__second   flex flex-col justify-between h-full items-end"></div>

        <div className="footer__container__copyrights flex flex-col justify-between items-center px-16">
          <div className="footer__container__copyrights--title text-[2.5rem] font-extrabold flex">
            {/* <a href="https://e.satra.ir/validation" target="_blank">
              <img
                referrerPolicy="origin"
                id="rgvjesgtnbqewlaosizpnbqe"
                alt="logo-samandehi"
                className="logo-img" // اضافه کردن کلاس جدید
                src={require("../../assets/samandehi.jpg")}
              />
            </a> */}

            {/* enamad for qorani */}
            <a
              referrerpolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=532137&Code=dBrM4LkBzJOvpMedC8sq1F33cWeORd0E"
            >
              <img
                referrerpolicy="origin"
                src="https://trustseal.enamad.ir/logo.aspx?id=532137&Code=dBrM4LkBzJOvpMedC8sq1F33cWeORd0E"
                alt=""
                className="logo-img" // اضافه کردن کلاس جدید
                style={{ width: "100px" }}
                code="dBrM4LkBzJOvpMedC8sq1F33cWeORd0E"
              />
            </a>
            <a href="https://www.irannsr.org/" target="_blank">
              <img
                referrerPolicy="origin"
                id="rgvjesgtnbqewlaosizpnbqe"
                alt="logo-samandehi"
                className="logo-img " // اضافه کردن کلاس جدید
                src={require("../../assets/headerLogo.png")}
              />
            </a>
          </div>
        </div>

        <div className="footer__container__section   w-full  flex flex-nowrap justify-evenly content-center flex-row-reverse">
          <div className="footer__container__links   h-full py-0 px-4 flex flex-col justify-between items-end text-right gap-y-[.5rem] text-[1.3rem]">
            <p className="footer__container__links--title   text-color-light font-bold text-[1rem]">
              درباره سایت
            </p>
            {/* <Link className="footer__container__links--link   no-underline text-color-grey text-[0.8em]">
              درباره ما
            </Link>
            <Link className="footer__container__links--link   no-underline text-color-grey text-[0.8em]">
              همکاران ما
            </Link> */}
            <Link
              to="/contact-us"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              ارتباط با ما
            </Link>
            <Link
              to="/terms"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              قوانین و مقررات
            </Link>
          </div>
          <div className="footer__container__links   h-full py-0 px-4 flex flex-col justify-between items-end text-right gap-y-[.5rem] text-[1.3rem]">
            <p className="footer__container__links--title   text-color-light font-bold text-[1rem]">
              لینک های مهم
            </p>
            <Link
              to="/quran"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              قرآن
            </Link>
            <Link
              to="courseList"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              آموزش
            </Link>
            <Link
              to="/madahi"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              مداحی
            </Link>
            <Link
              to="/series"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              سریال
            </Link>
          </div>
          <div className="footer__container__links   h-full py-0 px-4 flex flex-col justify-between items-end text-right gap-y-[.5rem] text-[1.3rem]">
            <p className="footer__container__links--title   text-color-light font-bold text-[1rem]">
              لینک های دیگر
            </p>
            <Link
              to="/articles"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              مقالات
            </Link>
            <Link
              to="/doubts"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              شبهات
            </Link>
            <Link
              to="/documentary"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              مستند
            </Link>
            <Link
              to="/video"
              className="footer__container__links--link   no-underline text-color-grey text-[0.8em]"
            >
              ویدیو
            </Link>
          </div>
        </div>

        <div className="footer__container__copyrights flex flex-col justify-between items-center px-16">
          <div className="footer__container__copyrights--title text-[2.5rem] font-extrabold">
            <img
              src={Logo}
              alt="logo"
              className="footer__container__copyrights--logo   text-color-light inline-block mb-4 w-30 h-20 "
            />
          </div>
          <p className="footer__container__copyrights--text  text-color-grey text-right text-[.8em]">
            تمامی حقوق مادی و معنوی سایت متعلق به شرکت دانش بنیان ویرا سگال کارو
            میباشد
          </p>
          <br />
          {/* <a href="https://saba.host/" target="_blank">
            <img
              referrerPolicy="origin"
              id="rgvjesgtnbqewlaosizpnbqe"
              alt="logo-samandehi"
              className="footer__container__logo"
              src={require("../../assets/sabahost.png")}
              style={{ width: "100px", height: "100px" }}
            />
          </a> */}
          {/* <p className="footer__container__copyrights--text  text-color-grey text-right text-[.8em]">
            قدرت گرفته از سرور های پر سرعت صباهاست
          </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
