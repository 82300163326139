import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import "./drawer.scss";

const DrawerComponent = ({ setIsLoading, surahNumber }) => {
  const [allSurahs, setAllSurahs] = useState([]);
  useEffect(
    () => async () => {
      const surahs = await axios.get("http://api.alquran.cloud/v1/surah");
      if (surahs.data) {
        setAllSurahs(surahs.data.data);
      }
    },
    []
  );

  return (
    <div className="drawerMenu">
      {allSurahs?.map((surah) => {
        return (
          <Link
            to={`/quran/${surah.number}`}
            key={surah.number}
            className={
              Number(surahNumber) === surah.number
                ? "drawerMenu__surah drawerMenu__surahBackground"
                : "drawerMenu__surah"
            }
            onClick={() => setIsLoading(true)}
          >
            <p className="drawerMenu__surah--number">
              {toFarsiNumber(surah.number)}
            </p>
            <div className="drawerMenu__surah--name">
              {/* {surah.name} */}
              {(surah.number < 10 && (
                <h1 className="surah__drawer--surahName">
                  00{surah.number}surah
                </h1>
              )) ||
                (surah.number >= 10 && surah.number < 100 && (
                  <h1 className="surah__drawer--surahName">
                    0{surah.number}surah
                  </h1>
                )) ||
                (surah.number >= 100 && (
                  <h1 className="surah__drawer--surahName">
                    {surah.number}surah
                  </h1>
                ))}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default DrawerComponent;
