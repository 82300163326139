import React from "react";
import "./subscription.scss";
import { MdOutlineSubscriptions } from "react-icons/md";

const Subscription = () => {
  return (
    <div className="profile__main__leftContainer__left">
      <div className="profile__main__leftContainer__left__sectionmark">
        <div className="profile__main__leftContainer__left__sectionmark__logo">
          <MdOutlineSubscriptions />
        </div>
      </div>
      <div className="profile__main__leftContainer__left__create">
        <p className="profile__main__leftContainer__left__create--text">
          برای تماشای بدون محدودیت، اشتراک بخرید
        </p>
        <div className="profile__main__leftContainer__left__create__submit">
          <button className="profile__main__leftContainer__left__create__submit--button">
            بروز رسانی
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
