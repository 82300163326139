import React, { useState } from "react";
import { MdAddAPhoto } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import "./userInfo.scss";

const UserInfo = () => {
  const [showGenderDropDown, setShowGenderDropDown] = useState(false);
  return (
    <div className="profile__main__leftContainer__left">
      <div className="profile__main__leftContainer__left__setpic">
        <div className="profile__main__leftContainer__left__setpic__logo">
          <MdAddAPhoto size={54} />
          <img
            src=""
            alt=""
            className="profile__main__leftContainer__left__setpic__logo--userpic"
          />
        </div>
      </div>
      <div className="profile__main__leftContainer__left__setinfo">
        <div className="profile__main__leftContainer__left__setinfo__inputContainer">
          <label className="profile__main__leftContainer__left__setinfo__inputContainer--label">
            نام و نام خانوادگی
          </label>
          <input
            className="profile__main__leftContainer__left__setinfo__inputContainer--input"
            type="text"
            placeholder="نام و نام خانوادگی خود را وارد کنید"
          />
        </div>
        <div className="profile__main__leftContainer__left__setinfo__inputContainer">
          <label className="profile__main__leftContainer__left__setinfo__inputContainer--label">
            ایمیل
          </label>
          <input
            className="profile__main__leftContainer__left__setinfo__inputContainer--input"
            type="email"
            placeholder="آدرس ایمیل خود را وارد نمایید"
          />
        </div>
        <div className="profile__main__leftContainer__left__setinfo__inputContainer">
          <label className="profile__main__leftContainer__left__setinfo__inputContainer--label">
            تاریخ تولد
          </label>
          <input
            className="profile__main__leftContainer__left__setinfo__inputContainer--input"
            type="text"
            placeholder="روز / ماه / سال"
          />
        </div>
        <div className="profile__main__leftContainer__left__setinfo__inputContainer">
          <label className="profile__main__leftContainer__left__setinfo__inputContainer--label">
            جنسیت
          </label>
          <div
            onClick={() => {
              setShowGenderDropDown(!showGenderDropDown);
            }}
            className="profile__main__leftContainer__left__setinfo__inputContainer__selectContainer"
          >
            <p className="profile__main__leftContainer__left__setinfo__inputContainer__selectContainer--placeholder">
              جنسیت خود را انتخاب کنید
            </p>
            <IoIosArrowDown />
          </div>
          {showGenderDropDown && (
            <ul
              className="profile__main__leftContainer__left__setinfo__inputContainer__selectContainer__select"
              name=" "
            >
              <li className="profile__main__leftContainer__left__setinfo__inputContainer__selectContainer__select--text">
                مرد
              </li>
              <li className="profile__main__leftContainer__left__setinfo__inputContainer__selectContainer__select--text">
                زن
              </li>
              <li className="profile__main__leftContainer__left__setinfo__inputContainer__selectContainer__select--text">
                ترجیح میدهم اعلام نکنم
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="profile__main__leftContainer__left__submit">
        <button className="profile__main__leftContainer__left__submit--button">
          بروز رسانی
        </button>
      </div>
    </div>
  );
};

export default UserInfo;
