import axios from "axios";
import { showAuthBox } from "../reducers/auth/authSlice";
import { showLoginBox } from "../reducers/auth/loginSlice";
import { loginFailure, loginStart, loginSuccess, logout } from "./AuthActions";

export const login = async (user, dispatch, dispatchState, regularLogin) => {
  dispatch(loginStart());
  try {
    const res = await axios.post("/api/auth/login", user);
    dispatch(loginSuccess(res.data));
    if (regularLogin) {
      dispatchState(showLoginBox());
    } else {
      dispatchState(showAuthBox());
    }
    return "success";
  } catch (err) {
    console.log(err);
    dispatch(loginFailure());
  }
};

export const logOut = async (dispatch) => {
  dispatch(logout());
};
