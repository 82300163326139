import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoginBox: false,
};

export const loginSlice = createSlice({
  name: "showLoginBox",
  initialState,
  reducers: {
    showLoginBox: (state, action) => {
      state.showLoginBox = !state.showLoginBox;
    },
  },
});

export const { showLoginBox } = loginSlice.actions;
export default loginSlice.reducer;
