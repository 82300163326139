import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { FaPlay } from "react-icons/fa";
import Background2 from "../../assets/banner/background2.jpg";
import Background1 from "../../assets/banner/background.jpg";
import "./bannerSlider.scss";
import { Link } from "react-router-dom";

const BannerSlider = () => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <Splide
      onVisible={() =>
        setTimeout(() => {
          setShowInfo(true);
        }, 300)
      }
      onHidden={() => {
        setShowInfo(false);
      }}
      options={{
        perMove: 1,
        width: "100%",
        type: "fade",
        rewind: true,
        arrows: false,
        drag: true,
        autoplay: true,
        interval: 5000,
        speed: 1000,
        pauseOnHover: false,
      }}
      aria-label="My Favorite Images"
    >
      <SplideSlide>
        <div
          className="slide"
          style={{ backgroundImage: `url(${Background2})` }}
        >
          <div className={showInfo ? "slide__slider" : "slide__slider__hide"}>
            <div className="slide__slider__content">
              <Link
                to="/live"
                state={{
                  // source: "https://win.holol.com/live/quran/playlist.m3u8",
                  // source: "https://www.elahmad.com/tv/embed.php?id=qoran_tv1",
                  source: "http://www.elahmad.com/tv/radiant.php?id=qoran_tv1",
                }}
                className="slide__slider__content--button"
              >
                <FaPlay size="1.5em" color="#333" />
              </Link>
              <h3 className="slide__slider__content--title">
                اکران آنلاین و تلاوت
              </h3>
              <img
                className="slide__slider__content--img"
                src={require("../../assets/programs/kaaba2.jpg")}
                alt="kaaba"
              />
              <h1>مکه مکرمه</h1>
              <p className="slide__slider__content--description">
                تصاویر آنلاین از مکه مکرمه و تلاوت قرآن کریم
              </p>
            </div>
          </div>
          <div className={showInfo ? "slide__info" : "slide__info__hide"}>
            <Link
              to="/live"
              state={{
                // source: "https://www.elahmad.com/tv/embed.php?id=qoran_tv1",
                source: "https://www.elahmad.com/tv/radiant.php?id=qoran_tv1",
              }}
              className="slide__show"
            >
              تماشا کنید
              <FaPlay size="1.5em" color="#333" />
            </Link>
            <h1 className="slide__info">تماشای مناسک حج</h1>
            <p className="slide__info">
              اجرای مناسک حج و ادای نماز عید سعید قربان
            </p>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div
          className="slide"
          style={{ backgroundImage: `url(${Background1})` }}
        >
          <div className={showInfo ? "slide__slider" : "slide__slider__hide"}>
            <div className="slide__slider__content">
              <Link
                to="/live"
                state={{
                // source: "https://www.elahmad.com/tv/embed.php?id=qoran_tv1",
                source: "https://www.elahmad.com/tv/radiant.php?id=qoran_tv1",

                }}
                className="slide__slider__content--button"
              >
                <FaPlay size="1.5em" color="#333" />
              </Link>
              <h3 className="slide__slider__content--title">
                اکران آنلاین زنده و تلاوت
              </h3>
              <img
                className="slide__slider__content--img"
                src={require("../../assets/programs/madinah.jpg")}
                alt="kaaba"
              />
              <h1>مدینه منوره</h1>
              <p className="slide__slider__content--description">
                تصاویر آنلاین از مدینه منوره و تلاوت قرآن کریم
              </p>
            </div>
          </div>
          <div
            className={
              showInfo ? "slide__info" : "slide__info slide__info__hide"
            }
          >
            <Link
              to="/live"
              state={{
                // source: "https://www.elahmad.com/tv/embed.php?id=qoran_tv1",
                source: "https://www.elahmad.com/tv/radiant.php?id=qoran_tv1",
              }}
              className="slide__show"
            >
              تماشا کنید
              <FaPlay size="1.5em" color="#333" />
            </Link>
            <h1 className="slide__info">تصاویر آنلاین مسجد النبی</h1>
            <p className="slide__info">اداء نماز و تلاوت قرآن کریم</p>
          </div>
        </div>
      </SplideSlide>
    </Splide>
  );
};

export default BannerSlider;
