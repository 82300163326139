import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../authContext/AuthContext";
import { useDispatch } from "react-redux";
import { showAuthBox } from "../../reducers/auth/authSlice";
import { showLoginBox } from "../../reducers/auth/loginSlice";
import Logo from "../../assets/logo.png";
import {
  RiAccountCircleFill,
  RiLogoutBoxRLine,
  RiUserLine,
  RiBookmarkLine,
} from "react-icons/ri";
import { MdOutlineSubscriptions } from "react-icons/md";
import MenuIcon from "@mui/icons-material/Menu";

import "./navbar.scss";
import { logOut } from "../../authContext/apiCalls";

const Navbar = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const { user, dispatch } = useContext(AuthContext);
  const dispatchState = useDispatch();

  const handleLogout = () => {
    setShowUserModal(false);
    logOut(dispatch);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar__right   flex justify-start items-center flex-nowrap flex-row-reverse">
          <Link to="/">
            <img
              className="navbar__right--logo   w-16 h-12"
              src={Logo}
              alt="logo"
            />
          </Link>
          <div className="navbar__right__dropdown   relative hidden">
            <button className="navbar__right__dropdown--btn   text-black mr-[.2rem] bg-transparent border-none cursor-pointer">
              {showDropDown ? (
                <svg
                  onClick={() => setShowDropDown(!showDropDown)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000"
                  width="1.5rem"
                  height="1.5rem"
                  viewBox="-3.5 0 19 19"
                  className="cf-icon-svg"
                >
                  <path d="M11.383 13.644A1.03 1.03 0 0 1 9.928 15.1L6 11.172 2.072 15.1a1.03 1.03 0 1 1-1.455-1.456l3.928-3.928L.617 5.79a1.03 1.03 0 1 1 1.455-1.456L6 8.261l3.928-3.928a1.03 1.03 0 0 1 1.455 1.456L7.455 9.716z" />
                </svg>
              ) : (
                <MenuIcon onClick={() => setShowDropDown(!showDropDown)} />
              )}
            </button>
          </div>
        </div>
        <div className="navbar__links    flex justify-between">
          <Link className="navbar__links--link   p-2  no-underline" to="/">
            خانه
          </Link>
          <Link className="navbar__links--link   p-2  no-underline" to="/quran">
            قرآن
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="courseList"
          >
            آموزش ها
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="/madahi"
          >
            مداحی
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="/podcast"
          >
            پادکست ها
          </Link>
          <Link className="navbar__links--link   p-2  no-underline" to="/video">
            ویدئو ها
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="/articles"
          >
            مقالات
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="/doubts"
          >
            شبهات
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="/series"
          >
            فیلم و سریال
          </Link>
          <Link
            className="navbar__links--link   p-2  no-underline"
            to="/documentary"
          >
            مستند
          </Link>
        </div>
        <div className="navbar__icons   flex justify-between items-center">
          {user ? (
            <div className="navbar__icons__account   p-2 flex items-center">
              <RiAccountCircleFill
                size={30}
                color="#a8b6ba"
                onClick={() => setShowUserModal(true)}
              />
              <div
                className={
                  showUserModal
                    ? "navbar__icons__account__modalContainer"
                    : "navbar__icons__account__modalContainer navbar__icons__account__modalContainer--hide"
                }
                onClick={() => setShowUserModal(false)}
              >
                <div
                  className={
                    showUserModal
                      ? "navbar__icons__account__modalContainer__modal"
                      : "navbar__icons__account__modalContainer__modal navbar__icons__account__modalContainer__modalHide"
                  }
                >
                  <div className="navbar__icons__account__modalContainer__modal--triangle"></div>
                  <Link
                    to="/profile/subscriptions"
                    className="navbar__icons__account__modalContainer__modal__item"
                  >
                    <MdOutlineSubscriptions className="navbar__icons__account__modalContainer__modal__item--icon" />
                    <p>خرید اشتراک</p>
                  </Link>
                  <Link
                    to="/profile/favorites"
                    className="navbar__icons__account__modalContainer__modal__item"
                  >
                    <RiBookmarkLine className="navbar__icons__account__modalContainer__modal__item--icon" />
                    <p>نشان شده ها</p>
                  </Link>
                  <Link
                    to="/profile/account"
                    className="navbar__icons__account__modalContainer__modal__item"
                  >
                    <RiUserLine className="navbar__icons__account__modalContainer__modal__item--icon" />
                    <p>حساب کاربری</p>
                  </Link>
                  <div
                    to="#"
                    className="navbar__icons__account__modalContainer__modal__item"
                    onClick={handleLogout}
                  >
                    <RiLogoutBoxRLine className="navbar__icons__account__modalContainer__modal__item--icon" />
                    <p>خروج از حساب کاربری</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <button
                className="navbar__btn navbar__btn--login"
                onClick={() => dispatchState(showLoginBox())}
              >
                ورود
              </button>
              <button
                className="navbar__btn navbar__btn--register"
                onClick={() => dispatchState(showAuthBox())}
              >
                ثبت نام
              </button>
            </>
          )}
        </div>
      </nav>
      <div
        className={
          showDropDown
            ? "navbar__right__dropdown__links   w-full mx-0 p-2 fixed right-0 top-[4.8rem] pr-[2rem] content-start items-start  text-[1em] z-[15]"
            : "navbar__right__dropdown--hidden "
        }
      >
        <Link
          className="navbar__right__dropdown__links--link no-underline p-2"
          to="/"
        >
          خانه
        </Link>
        <Link
          className="navbar__right__dropdown__links--link no-underline p-2"
          to="/quran"
        >
          قرآن
        </Link>
        <Link
          className="navbar__right__dropdown__links--link no-underline p-2"
          to="courseList"
        >
          آموزش ها
        </Link>
        <Link
          className="navbar__right__dropdown__links--link no-underline p-2"
          to="/podcast"
        >
          پادکست ها
        </Link>
        <Link className="navbar__links--link   p-2  no-underline" to="/madahi">
          مداحی
        </Link>
        <Link
          className="navbar__right__dropdown__links--link no-underline p-2"
          to="/video"
        >
          ویدئو ها
        </Link>
        <Link
          className="navbar__links--link   p-2  no-underline"
          to="/articles"
        >
          مقالات
        </Link>
        <Link
          className="navbar__right__dropdown__links--link no-underline p-2"
          to="/series"
        >
          فیلم و سریال
        </Link>
        <Link className="navbar__links--link   p-2  no-underline" to="/doubts">
          شبهات
        </Link>
        <Link
          className="navbar__links--link   p-2  no-underline"
          to="/documentary"
        >
          مستند
        </Link>
      </div>
    </>
  );
};

export default Navbar;
