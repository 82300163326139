export const channelCategories = {
  tv: [
    "شبکه یک",
    "شبکه دو",
    "شبکه سه",
    "شبکه قرآن",
    "شبکه ورزش",
    "شبکه چهار",
    "شبکه پنج",
    "شبکه آموزش",
    "شبکه آی فیلم",
    "شبکه مستند",
    "شبکه نمایش",
    "شبکه نسیم",
    "شبکه خبر",
    "شبکه افق",
    "شبکه امید",
    "شبکه پویا",
    "شبکه سلامت",
    "شبکه سپهر",
    "شبکه تماشا",
  ],
  radio: [
    "رادیو آوا",
    "رادیو فرهنگ",
    "رادیو ایران",
    "رادیو معارف",
    "رادیو قرآن",
    "رادیو سلامت",
    "رادیو ورزش",
  ],
  states: [
    "شبکه آفتاب",
    "شبکه البرز",
    "شبکه باران",
    "شبکه بوشهر",
    "شبکه دنا",
    "شبکه فارس",
    "شبکه هامون",
    "شبکه اصفهان",
    "شبکه خلیج فارس",
    "شبکه خاوران",
    "شبکه خوزستان",
    "شبکه کردستان",
    "شبکه خراسان",
    "شبکه قزوین",
    "شبکه سبلان",
    "شبکه سهند",
    "شبکه سمنان",
    "شبکه تابان",
  ],
  exclusive: [],
};
