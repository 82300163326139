import axios from "axios";
import React, { useEffect, useState } from "react";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import { useParams } from "react-router-dom";
import DrawerComponent from "../../Components/drawer/DrawerComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import "./surah.scss";
import { LinearProgress } from "@mui/material";

const Surah = () => {
  const [surah, setSurah] = useState({});
  const [translation, setTranslation] = useState({});
  const { surahNumber } = useParams();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(async () => {
      const res = await axios.get(`/api/surahs/${surahNumber}`);
      setSurah(res.data);
      // const res = await axios.get(
      //   `http://api.alquran.cloud/v1/surah/${surahNumber}`
      // );
      // const englishRes2 = await axios.get(
      //   `http://api.alquran.cloud/v1/surah/${surahNumber}/editions/fa.makarem`
      // );
      const translation = await axios.get(`/api/translations/${surahNumber}`);

      setTranslation(translation.data.ayahs);
      setIsLoading(false);
    }, 1000);
  }, [surahNumber]);

  return (
    <div className="surah">
      {isLoading && (
        <div className="surah__content__loading">
          <LinearProgress color="inherit" />
        </div>
      )}
      <div className="surah__drawer">
        <button
          className="surah__drawer__button"
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          {drawerOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          {/* {surah.name} */}
          {(surah.number < 10 && (
            <h1 className="surah__drawer--surahName">00{surah.number}surah</h1>
          )) ||
            (surah.number >= 10 && surah.number < 100 && (
              <h1 className="surah__drawer--surahName">0{surah.number}surah</h1>
            )) ||
            (surah.number >= 100 && (
              <h1 className="surah__drawer--surahName">{surah.number}surah</h1>
            ))}
        </button>
      </div>

      <div
        className={
          drawerOpen
            ? "surah__drawer__container surah__drawer__containerShow"
            : "surah__drawer__container"
        }
      >
        <DrawerComponent
          setIsLoading={setIsLoading}
          surahNumber={surahNumber}
        />
      </div>

      <div
        className={drawerOpen ? "surah__content marginLeft" : "surah__content"}
      >
        {surah === {} && (
          <div className="surah__content__loading">
            <LinearProgress color="inherit" />
          </div>
        )}
        <div className="surah__content__start">
          {(surah.number < 10 && (
            <h1 className="surah__content__start--name">
              00{surah.number}surah
            </h1>
          )) ||
            (surah.number >= 10 && surah.number < 100 && (
              <h1 className="surah__content__start--name">
                0{surah.number}surah
              </h1>
            )) ||
            (surah.number >= 100 && (
              <h1 className="surah__content__start--name">
                {surah.number}surah
              </h1>
            ))}
        </div>
        <div className="surah__content__verses">
          {surah?.ayahs?.map((verse, index) => {
            return (
              <div key={verse.number} className="surah__content__verses__verse">
                <div className="surah__content__verses__verse__arabic">
                  {verse.text}
                  <p className="surah__content__verses__verse__arabic__number">
                    <span className="surah__content__verses__verse__arabic__number__icon">
                      <span className="surah__content__verses__verse__arabic__number__icon--num">
                        {toFarsiNumber(verse.numberInSurah)}
                      </span>
                    </span>
                  </p>
                </div>
                <p className="surah__content__verses__verse--translation">
                  {translation[index]?.text}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Surah;
